.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ocupa toda la altura de la ventana */
    font-size: 1.5em; /* Tamaño del texto */
    color: #333; /* Color del texto */
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.2); /* Color de fondo del spinner */
    border-left-color: #007bff; /* Color del spinner */
    border-radius: 50%;
    width: 50px; /* Tamaño del spinner */
    height: 50px; /* Tamaño del spinner */
    animation: spin 1s linear infinite; /* Animación de giro */
  }
  
  /* Animación del spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  