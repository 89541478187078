body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f7f0ef;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  padding: 20px;
}

.container {
  width: 90%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
}

.chat-messages {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 400px;
  padding: 10px 20px;
}

.question-message {
  position: relative; /* Opcional, dependiendo de tu estructura */
  z-index: 1; /* Menor z-index para que quede de trasfondo */
  opacity: 0.6; /* Opcional: reduce la opacidad para dar efecto de trasfondo */
}
.answer-message {
  position: relative; /* Importante para que z-index funcione */
  z-index: 2; /* Mayor z-index para que quede en primer plano */
}
.message {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  position: relative;
  max-width: 80%;
}

.user-message {
  background-color: #dcf8c6; /* Color de fondo para mensajes del usuario */
  color: #303030; /* Color del texto para mensajes del usuario */
  font-weight: bold; /* Texto en negrita para resaltar */
  border-radius: 10px; /* Bordes redondeados para un aspecto más suave */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para agregar profundidad */
  align-self: flex-start;
}

.bot-message {
  background-color: #f0f8ff; /* Color de fondo para mensajes del bot */
  color: #333; /* Color del texto para mensajes del bot */
  font-style: italic; /* Texto en cursiva para diferenciar */
  border-radius: 10px; /* Bordes redondeados para un aspecto más suave */
  padding: 10px; /* Espacio interior alrededor del texto para separarlo del borde */
  margin: 5px 0; /* Margen exterior para separar los mensajes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para agregar profundidad */
}



.user::after, .bot::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 0;
  margin-top: -5px;
}

.user::after {
  right: -10px;
  border-top-color: #dcf8c6;
}

.bot::after {
  left: -10px;
  border-top-color: #ececec;
}

.input-container {
  display: flex;
  gap: 10px;
}

.input-field {
  flex-grow: 1; /* Ocupa el espacio disponible */
  border: 2px solid #007bff; /* Borde del input */
  border-radius: 20px; /* Bordes redondeados */
  padding: 10px; /* Espacio interior */
  outline: none; /* Eliminar el borde de enfoque por defecto */
  transition: border-color 0.3s; /* Transición suave para el color del borde */
  min-height: 30px; /* Altura mínima */
  height: auto; /* Ajusta la altura automáticamente */
  overflow-y: auto; /* Permitir desplazamiento vertical */
  resize: none; /* Evitar el redimensionamiento manual */
}

.input-field:focus {
  border-color: #0056b3;
}


.send-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%; /* Hacer el botón circular */
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px; /* Ajustar el tamaño del botón */
  height: 40px; /* Ajustar el tamaño del botón */
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button:hover, .send-button.sending {
  background-color: #0056b3;
}

.send-button.sending {
  background-color: #95a5a6;
}

.container.light {
  background-color: #fff;
  color: #000;
}

.container.dark {
  background-color: #333;
  color: #fff;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Aplicar la animación a los mensajes del usuario y del bot */
.user-message, .bot-message {
  animation: fadeIn 0.5s ease-in-out forwards;
}

/* Mejora en el contenedor de entrada para dispositivos móviles */
@media (max-width: 768px) {
  .user-message, .bot-message {
    font-size: 14px; /* Reducción del tamaño de fuente para adaptarse a pantallas pequeñas */
  }
}
/* Estilo para el botón de cambio de tema */
.theme-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.theme-button:hover {
  background-color: #222424;
}


.bot-response-container {
  background-color: #f0f8ff; /* Un color de fondo claro para las respuestas del bot */
  color: #333; /* Color del texto oscuro para contraste */
  font-style: italic; /* Texto en cursiva para diferenciar */
  border-radius: 10px; /* Bordes redondeados para un aspecto más suave */
  padding: 10px; /* Espacio interior alrededor del texto */
  margin: 5px 0; /* Margen exterior para separar los mensajes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para agregar profundidad */
}


.theme-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%; /* Hacer el botón circular */
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px; /* Ajustar el tamaño del botón */
  height: 40px; /* Ajustar el tamaño del botón */
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-button:hover {
  background-color: #0056b3;
}




@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.listening {
  animation: pulse 1s infinite;
}

.microphone-button {
  background-color: #007bff; /* Color de fondo */
  color: white; /* Color del icono */
  border: none; /* Sin borde */
  border-radius: 50%; /* Botón circular */
  padding: 10px; /* Espacio interior */
  cursor: pointer; /* Cursor en forma de mano */
  transition: background-color 0.3s; /* Transición suave para el cambio de color */
  width: 40px; /* Ancho del botón */
  height: 40px; /* Altura del botón */
  display: flex; /* Flexbox para centrar el icono */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  bottom: 20px; /* Espacio desde el fondo */
  right: 20px; /* Espacio desde el borde derecho */
}


.logo {
  width: 20%; /* Ajusta el ancho como prefieras */
  position: relative;
  left: 40%;
  
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.containerLogo {
  flex: 1;
}

.appFooter {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0; /* Color de fondo del footer */
  color: #000; /* Color del texto */
}




