.records-calls {
    list-style-type: none;
    padding-left: 20px;
    font-family: Arial, sans-serif;
  }
  
  .records-calls li {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8f9fa; /* Color de fondo suave */
    border: 1px solid #dee2e6; /* Borde sutil */
    transition: background-color 0.3s;
  }
  
  .records-calls li:hover {
    background-color: #e2e6ea; /* Cambio de color al pasar el ratón */
  }
  
  .records-calls strong {
    color: #007bff; /* Color azul para las claves */
  }
  
  .primitive-value {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: #d1ecf1; /* Fondo para valores primitivos */
    color: #0c5460; /* Color del texto */
  }
  