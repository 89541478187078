.appHeader {
    background-color: #282c34; /* Color de fondo oscuro */
    padding: 20px; /* Espaciado interno */
    color: white; /* Color del texto */
    text-align: center; /* Alineación del texto */
  }
  
  .appHeader h1 {
    margin: 0; /* Remueve el margen por defecto */
    font-size: 2rem; /* Tamaño del texto */
  }
  .logout-button {
    background-color: #ec7207; /* Color de fondo atractivo */
    color: white; /* Color de texto */
    padding: 05px 10px; /* Espaciado interno */
    border: none;
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease; /* Suaviza el cambio de color */
  }
  .logout-button:hover {
    background-color: #ff4c4c; /* Color al pasar el ratón */
  }

  .logout-button {
    margin-left: 700px; /* Empuja el botón a la derecha */
  }