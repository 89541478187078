.container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
  }
  
  h1 {
    color: #343a40;
  }
  
  .custom-button {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
  
  .custom-button:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  
  .custom-list-item {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .trash-icon {
    cursor: pointer;
    color: red;
  }
  
  .trash-icon:hover {
    color: darkred;
  }

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .custom-page-item {
    margin: 0 5px;
  }
  
  .custom-page-link {
    color: #007bff;
    border: 1px solid #dee2e6;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
  }
  
  .custom-page-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .custom-active .custom-page-link {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }