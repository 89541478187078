.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.upload-container h1 {
  margin-bottom: 10px;
  color: #333;
}

.upload-container p {
  margin-bottom: 20px;
  color: #666;
}

.file-input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.upload-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.upload-button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.upload-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}

.file-info {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
}

.progress-bar {
  height: 8px;
  background-color: #007bff;
  width: 0; /* Se ajustará con JavaScript */
  transition: width 0.4s ease;
}


.text-input-key {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  max-width: 400px;
  font-size: 16px;
}

.text-input-index{
  margin: 10px 0;
  position: relative;
  left: -47%;
  padding: 8px;
  width: 185%;
  max-width: 500px;
  font-size: 16px;
}


.test-button {
  background-color: #28a745; /* Verde */
  color: white; /* Texto blanco */
  border: none; /* Sin borde */
  padding: 5px 10px; /* Botón pequeño */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 20px; /* Tamaño de fuente más pequeño */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave */
}

.test-button:hover {
  background-color: #218838; /* Verde más oscuro al pasar el ratón */
}

.test-button:active {
  background-color: #1e7e34; /* Verde aún más oscuro cuando está activo */
}
