body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #fcfcfc 0%, #ec720742 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.logoAsertis {
  width: 100px; /* Ajusta el tamaño según lo necesites */
  margin-bottom: 20px; /* Espaciado debajo del logo */
  position: relative;
}


.login-container {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 350px;
  position: relative;
  left: 25%;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

h2 {
  margin-bottom: 25px;
  color: #333;
  font-size: 24px;
}

.error {
  color: rgb(190, 47, 47);
  margin-bottom: 15px;
  font-weight: bold;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input {
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.login-button {
  padding: 12px;
  background-color: #ec7207;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.login-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Animaciones */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
